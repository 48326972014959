// src/App.js
import './index.css';
import axios from 'axios';
import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import Redirect from './Pages/Redirect/Redirect';

function App() {
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=8a6b6d8aaae34154875473381a1de6bd');
        const country = response.data.country_code2;
        console.log('Country:', country);
        if (country === 'AE') {
          window.location.href = 'https://dynamicleo.ae';
        }
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    fetchLocation();
  }, []);

  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/freeshopifyseminar" element={<Redirect link="https://www.facebook.com/share/p/CbH6cGR3VbmnSCvJ/" />} />
        <Route exact path="/classshopify" element={<Redirect link="https://dynamicleoecommerce.com/" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
